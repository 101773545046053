import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Button, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SignOutIcon from '@material-ui/icons/ExitToApp';

import { toggleMenu } from '~/store/modules/env/actions';
import { signOut } from '~/store/modules/auth/actions';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
}));

const Topbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state.env.menuOpen);
  const { username } = useSelector(state => state.auth.user);

  const handleDrawer = () => {
    dispatch(toggleMenu());
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: menuOpen,
      })}
    >
      <Toolbar style={{ minHeight: 48, height: 48 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          className={clsx({ [classes.hide]: menuOpen })}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.flexGrow} />
        <Button onClick={handleSignOut} style={{ textTransform: 'none', color: '#FFF' }} endIcon={<SignOutIcon />}>
          <Hidden smDown>Olá, {username}</Hidden>
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
