export function signUpRequest(username, email, password, confirmPassword, cpf, rg, phoneNumber, address, profession, licenseNumber) {
  return {
    type: '@user/SIGN_UP_REQUEST',
    payload: {
      username,
      email,
      password,
      confirmPassword,
      cpf,
      rg,
      phoneNumber,
      address,
      profession,
      licenseNumber,
    },
  };
}

export function signUpSuccess() {
  return {
    type: '@user/SIGN_UP_SUCCESS',
  };
}

export function signUpFailure(registerError, fieldErrors) {
  return {
    type: '@user/SIGN_UP_FAILURE',
    payload: {
      registerError,
      fieldErrors,
    },
  };
}

export function forgotPasswordRequest(email) {
  return {
    type: '@user/FORGOT_PASSWORD_REQUEST',
    payload: {
      email,
    },
  };
}

export function forgotPasswordSuccess() {
  return {
    type: '@user/FORGOT_PASSWORD_SUCCESS',
  };
}

export function forgotPasswordFailure(forgotPasswordError) {
  return {
    type: '@user/FORGOT_PASSWORD_FAILURE',
    payload: {
      forgotPasswordError,
    },
  };
}

export function passwordResetRequest(password, confirmPassword, token) {
  return {
    type: '@user/PASSWORD_RESET_REQUEST',
    payload: {
      password,
      confirmPassword,
      token,
    },
  };
}

export function passwordResetSuccess() {
  return {
    type: '@user/PASSWORD_RESET_SUCCESS',
  };
}

export function passwordResetFailure(passwordResetError) {
  return {
    type: '@user/PASSWORD_RESET_FAILURE',
    payload: {
      passwordResetError,
    },
  };
}

export function clearError() {
  return {
    type: '@user/CLEAR_ERROR',
  };
}

export function OpenEnabledRoomsDialog() {
  return {
    type: '@user/OPEN_ENABLED_ROOMS_DIALOG',
  };
}

export function CloseEnabledRoomsDialog() {
  return {
    type: '@user/CLOSE_ENABLED_ROOMS_DIALOG',
  };
}

export function updateProfileInfo(key, value) {
  return {
    type: '@user/UPDATE_PROFILE_INFO',
    payload: {
      key,
      value,
    },
  };
}

export function changePasswordRequest(currentPassword, newPassword) {
  return {
    type: '@user/CHANGE_PASSWORD_REQUEST',
    payload: {
      currentPassword,
      newPassword,
    },
  };
}

export function changePasswordSuccess() {
  return {
    type: '@user/CHANGE_PASSWORD_SUCCESS',
  };
}

export function changePasswordFailure() {
  return {
    type: '@user/CHANGE_PASSWORD_FAILURE',
  };
}
