import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '~/assets/logo.svg';
import MaskedInput from 'react-text-mask';
import { Box, Card, TextField, Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { signUpRequest, clearError } from '~/store/modules/user/actions';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 16,
    borderRadius: 12,
    width: '50%',
    maxWidth: 700,
    minWidth: 315,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  linkText: {
    marginLeft: 4,
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
    maxWidth: 300,
  },
  buttonLoading: {
    width: '30%',
    minWidth: 60,
  },
}));

function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function CPFMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

export default function SignUp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, registerError } = useSelector(state => state.user);
  const fieldErrors = useSelector(state => state.user.fieldErrors) || {};

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cpf, setCPF] = useState('');
  const [rg, setRG] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [profession, setProfession] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');

  const handleSignUp = async e => {
    e.preventDefault();
    dispatch(signUpRequest(username, email, password, confirmPassword, cpf, rg, phoneNumber, address, profession, licenseNumber));
  };

  return (
    <Card className={classes.card} elevation={5}>
      <img src={logo} alt="Viver Integral Coworking" />
      <Box mt={2} width="90%">
        <Typography align="center">
          Olá, que bom que você está interessado em se tornar um profissional do nosso espaço! Para dar sequência ao seu cadastro,
          precisamos que preencha os campos abaixo:
        </Typography>
      </Box>

      <form onSubmit={handleSignUp} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              variant="outlined"
              placeholder="Nome Completo"
              label="Nome Completo"
              fullWidth
              error={'username' in fieldErrors}
              helperText={'username' in fieldErrors ? fieldErrors.username : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="email"
              autoComplete="off"
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
              placeholder="exemplo@gmail.com"
              label="Email"
              type="email"
              fullWidth
              error={'email' in fieldErrors}
              helperText={'email' in fieldErrors ? fieldErrors.email : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              variant="outlined"
              placeholder="Senha"
              label="Senha"
              type="password"
              fullWidth
              error={'password' in fieldErrors}
              helperText={'password' in fieldErrors ? fieldErrors.password : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="confirm_password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              variant="outlined"
              placeholder="Confirme sua senha"
              label="Confirme sua senha"
              type="password"
              style={{ marginBottom: 0 }}
              fullWidth
              error={'confirmPassword' in fieldErrors}
              helperText={'confirmPassword' in fieldErrors ? fieldErrors.confirmPassword : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="cpf"
              value={cpf}
              onChange={e => setCPF(e.target.value)}
              variant="outlined"
              placeholder="CPF"
              label="CPF"
              fullWidth
              error={'cpf' in fieldErrors}
              helperText={'cpf' in fieldErrors ? fieldErrors.cpf : ''}
              InputProps={{
                inputComponent: CPFMask,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="rg"
              value={rg}
              onChange={e => setRG(e.target.value)}
              variant="outlined"
              placeholder="RG"
              label="RG"
              fullWidth
              error={'rg' in fieldErrors}
              helperText={'rg' in fieldErrors ? fieldErrors.rg : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="phone_number"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              variant="outlined"
              placeholder="Telefone"
              label="Telefone"
              fullWidth
              error={'phoneNumber' in fieldErrors}
              helperText={'phoneNumber' in fieldErrors ? fieldErrors.phoneNumber : ''}
              InputProps={{
                inputComponent: PhoneMask,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="address"
              value={address}
              onChange={e => setAddress(e.target.value)}
              variant="outlined"
              placeholder="Endereço"
              label="Endereço"
              fullWidth
              error={'address' in fieldErrors}
              helperText={'address' in fieldErrors ? fieldErrors.address : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="profession"
              value={profession}
              onChange={e => setProfession(e.target.value)}
              variant="outlined"
              placeholder="Profissão"
              label="Profissão"
              fullWidth
              error={'profession' in fieldErrors}
              helperText={'profession' in fieldErrors ? fieldErrors.profession : ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              name="license_number"
              value={licenseNumber}
              onChange={e => setLicenseNumber(e.target.value)}
              variant="outlined"
              placeholder="Registro de Profissão (se aplicável)"
              label="Registro de Profissão"
              fullWidth
              error={'licenseNumber' in fieldErrors}
            />
          </Grid>
        </Grid>

        <Box minHeight={16} mb={2}>
          {registerError && (
            <Typography variant="subtitle2" color="error" style={{ lineHeight: '16px' }}>
              {registerError}
            </Typography>
          )}
        </Box>

        <Box display="flex" justifyContent="center" width="100%">
          <Button
            type="submit"
            className={clsx(classes.button, {
              [classes.buttonLoading]: loading,
            })}
          >
            {loading ? <CircularProgress color="inherit" size={30} /> : 'Registrar'}
          </Button>
        </Box>

        <Box display="flex" justifyContent="center" mt={2} className={classes.textBox}>
          <Typography variant="h5">Ja possuí um usuário?</Typography>

          <Link to="/">
            <Typography variant="h5" color="secondary" className={classes.linkText}>
              Acesse aqui!
            </Typography>
          </Link>
        </Box>
      </form>
    </Card>
  );
}
