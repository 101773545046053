import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '~/assets/logo.svg';
import { Box, Card, TextField, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { clearError, passwordResetRequest } from '~/store/modules/user/actions';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 32,
    borderRadius: 12,
    '& .MuiTextField-root': {
      marginBottom: 16,
    },
    width: '50%',
    maxWidth: 500,
    minWidth: 315,
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  linkText: {
    marginLeft: 4,
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
  },
  buttonLoading: {
    width: '30%',
    minWidth: 60,
  },
}));

export default function SignUp({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { token } = match.params;
  const { loading, passwordResetError } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordRest = async e => {
    e.preventDefault();
    dispatch(passwordResetRequest(password, confirmPassword, token));
  };

  return (
    <Card className={classes.card} elevation={5}>
      <img src={logo} alt="Viver Integral Coworking" />
      <form onSubmit={handlePasswordRest} noValidate>
        <TextField
          name="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          variant="outlined"
          placeholder="Nova Senha"
          label="Nova Senha"
          type="password"
        />
        <TextField
          name="confirm_password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          variant="outlined"
          placeholder="Confirme sua nova senha"
          label="Confirme sua nova senha"
          type="password"
          style={{ marginBottom: 0 }}
        />

        <Box minHeight={16} mb={2}>
          {passwordResetError && (
            <Typography variant="subtitle2" color="error">
              {passwordResetError}
            </Typography>
          )}
        </Box>

        <Box display="flex" justifyContent="center" width="100%">
          <Button
            type="submit"
            className={clsx(classes.button, {
              [classes.buttonLoading]: loading,
            })}
          >
            {loading ? <CircularProgress color="inherit" size={30} /> : 'Trocar senha'}
          </Button>
        </Box>
      </form>
    </Card>
  );
}
