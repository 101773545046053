import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import env from './env/reducer';
import reservation from './reservation/reducer';
import recurrency from './recurrency/reducer';
import admin from './admin/reducer';

export default combineReducers({
  auth,
  user,
  env,
  reservation,
  recurrency,
  admin,
});
