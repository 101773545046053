import { Box, Button, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterIcon from '@material-ui/icons/FilterList';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getRoomName, getRoomNameMap } from '~/services/utils';
import { changePasswordRequest, OpenEnabledRoomsDialog, updateProfileInfo } from '~/store/modules/user/actions';




const useStyles = makeStyles(theme => ({
  halfField: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = createFilterOptions();

  const { username, email } = useSelector(state => state.auth.user);
  const { pacientList, defaultRoom, enabledRooms } = useSelector(state => state.user.profile);

  const [selectedPacient, setSelectedPacient] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      dispatch(changePasswordRequest(currentPassword, newPassword));
    } else {
      toast.error('As senhas não são iguais');
    }
  };

  const handleChangeDefaultRoom = defaultRoom => {
    dispatch(updateProfileInfo('defaultRoom', defaultRoom));
  };

  const handleChangePacient = pacient => {
    setSelectedPacient(pacient);

    if (pacient && !pacientList.includes(pacient.value)) {
      dispatch(updateProfileInfo('pacientList', [...pacientList, pacient.value].sort()));
      setSelectedPacient(null);
    }
  };

  const handleDeletePacient = () => {
    dispatch(updateProfileInfo('pacientList', [...pacientList.filter(p => p !== selectedPacient.value)]));
    setSelectedPacient(null);
  };

  return (
    <Grid container spacing={2} style={{ padding: 24 }}>
      <Grid item xs={12}>
        <Typography variant="h4" color="secondary">
          Informações da conta
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled
          name="email"
          autoComplete="off"
          value={username}
          onChange={() => {}}
          variant="outlined"
          label="Nome Completo"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          disabled
          name="email"
          autoComplete="off"
          value={email}
          onChange={() => {}}
          variant="outlined"
          placeholder="exemplo@gmail.com"
          label="Email"
          type="email"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Typography variant="h4" color="secondary">
          Informações de segurança
        </Typography>
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          name="password"
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          variant="outlined"
          placeholder="Senha atual"
          label="Senha atual"
          type="password"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          name="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          variant="outlined"
          placeholder="Senha nova"
          label="Senha nova"
          type="password"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          name="confirm_password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          variant="outlined"
          placeholder="Confirme sua nova senha"
          label="Confirme sua senha"
          type="password"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={3} container alignItems="center" justify="center">
        <Button fullWidth variant="contained" style={{ maxWidth: 200 }} onClick={handleChangePassword}>
          Alterar senha
        </Button>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 24 }}>
        <Typography variant="h4" color="secondary">
          Preferências do usuário
        </Typography>
      </Grid>

      <Grid item xs={12} container>
        <Box display="flex" alignItems="center" className={classes.halfField}>
          <Autocomplete
            fullWidth
            disableClearable
            value={defaultRoom ? { value: defaultRoom, label: getRoomName(defaultRoom) } : { value: null, label: '' }}
            onChange={(e, newValue) => handleChangeDefaultRoom(newValue.value)}
            options={[
              { value: null, label: 'Sem preferência' },
              ...Object.entries(getRoomNameMap())
                .filter(([value]) => enabledRooms[value])
                .map(([value, label]) => ({ value, label })),
            ]}
            getOptionSelected={option => option.value === defaultRoom}
            getOptionLabel={option => option.label}
            renderInput={params => <TextField {...params} label="Sala preferêncial" variant="outlined" />}
          />
          <Hidden smDown>
            <Button
              variant="contained"
              onClick={() => dispatch(OpenEnabledRoomsDialog())}
              style={{ marginLeft: 24, marginRight: 8, minWidth: 150 }}
              endIcon={<FilterIcon />}
            >
              Filtrar Salas
            </Button>
          </Hidden>
          <Hidden mdUp>
            <IconButton style={{ marginLeft: 16 }} onClick={() => dispatch(OpenEnabledRoomsDialog())}>
              <FilterIcon />
            </IconButton>
          </Hidden>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" className={classes.halfField}>
          <Autocomplete
            fullWidth
            value={selectedPacient || { value: '', label: '' }}
            onChange={(e, newValue) => handleChangePacient(newValue)}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== '') {
                filtered.push({
                  value: params.inputValue,
                  label: `Adicionar "${params.inputValue}"`,
                  inputValue: params.inputValue,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            options={pacientList.map(p => ({ value: p, label: p }))}
            getOptionLabel={option => {
              if (typeof option === 'string') return option;
              if (option.inputValue) return option.inputValue;
              return option.label;
            }}
            renderOption={option => option.label}
            freeSolo
            renderInput={params => (
              <TextField {...params} label="Lista de pacientes" placeholder="Busque ou adicione um paciente" variant="outlined" />
            )}
          />
          <IconButton style={{ marginLeft: 16 }} disabled={!selectedPacient} onClick={handleDeletePacient}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
