import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { CloseEnabledRoomsDialog, updateProfileInfo } from '~/store/modules/user/actions';
import { getRoomNameMap } from '~/services/utils';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { enabledRoomsDialog } = useSelector(state => state.user);
  const { enabledRooms, defaultRoom } = useSelector(state => state.user.profile);
  const [remainingRooms, setRemainingRooms] = useState();

  const handleChangeEnabledRooms = room => {
    if (room === defaultRoom) return;

    const updatedEnabledRooms = { ...enabledRooms };
    updatedEnabledRooms[room] = !updatedEnabledRooms[room];
    dispatch(updateProfileInfo('enabledRooms', updatedEnabledRooms));
  };

  const handleClose = () => {
    dispatch(CloseEnabledRoomsDialog());
  };

  useEffect(() => {
    setRemainingRooms(Object.values(enabledRooms).reduce((acc, enabled) => (enabled ? (acc += 1) : acc), 0));
  }, [enabledRooms]);

  const isDisabled = value => {
    const isDefault = value === defaultRoom;
    const isLastEnabled = enabledRooms[value] && remainingRooms === 1;
    return isDefault || isLastEnabled;
  };

  return (
    <Dialog open={enabledRoomsDialog} onClose={handleClose} maxWidth="xs" fullWidth>
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Seleção de salas</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <List component="div" disablePadding style={{ minWidth: 150 }}>
          {Object.entries(getRoomNameMap()).map(([value, label]) => (
            <ListItem key={value} button onClick={() => handleChangeEnabledRooms(value)} disabled={isDisabled(value)}>
              <FormControlLabel control={<Checkbox checked={enabledRooms[value]} color="secondary" />} />
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
