import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import env from './env/sagas';
import reservation from './reservation/sagas';
import recurrency from './recurrency/sagas';
import admin from './admin/sagas';

export default function* rootSaga() {
  return yield all([auth, user, env, reservation, recurrency, admin]);
}
