import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Card, Typography, Box, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ListIcon from '@material-ui/icons/List';
import TableIcon from '@material-ui/icons/EventNote';

import { listUserUpcomingRequest } from '~/store/modules/reservation/actions';
import { setViewMode } from '~/store/modules/env/actions';

import TableView from './TableView';
import ListView from './ListView';
import Statement from './Statement';

import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    minHeight: 120,
    flexDirection: 'column',
    width: '100%',
    padding: 12,
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { dates, userUpcomingReservations } = useSelector(state => state.reservation);
  const { viewMode } = useSelector(state => state.env);

  const [pageSize] = useState(isMobile ? 1 : 7);
  const [tableViewPage, setTableViewPage] = useState(0);
  const [tableViewStartDate, setTableViewStateDate] = useState([]);
  const [tableViewEndDate, setTableViewEndDate] = useState([]);
  const [tableViewCurrentSlice, setTableViewCurrentSlice] = useState([]);

  const handleChangeViewMode = () => {
    let newViewMode = 'table';
    if (viewMode === 'table') newViewMode = 'list';
    dispatch(setViewMode(newViewMode));
  };

  useEffect(() => {
    if (dates && dates.length > 0) {
      const startDateIndex = tableViewPage * pageSize;
      setTableViewCurrentSlice(dates.slice(startDateIndex, startDateIndex + pageSize));

      const [, sDate] = dates[startDateIndex] || '';
      const [, eDate] = dates[startDateIndex + pageSize - 1] || '';

      setTableViewStateDate(sDate.split(',')[1]);
      setTableViewEndDate(eDate.split(',')[1]);

      if (dates.length > 0 && userUpcomingReservations.length === 0) dispatch(listUserUpcomingRequest());
    }
  }, [dates, tableViewPage, userUpcomingReservations.length, dispatch, pageSize]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={8} style={{ padding: 12 }}>
        <Card className={classes.card}>
          {isMobile && (
            <Box display="flex" flexDirection="column" alignItems="center" width="100%" mb={2}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '48px' }} color="secondary">
                  Minhas Reservas
                </Typography>

                <IconButton onClick={handleChangeViewMode} color="primary">
                  {viewMode === 'table' ? <ListIcon /> : <TableIcon />}
                </IconButton>
              </Box>

              {viewMode === 'table' && (
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => setTableViewPage(tableViewPage - 1)}
                    disabled={tableViewPage <= 0}
                    color="secondary"
                    style={{ height: 24, width: 24 }}
                  >
                    <BackIcon fontSize="small" style={{ marginRight: -7 }} />
                  </IconButton>
                  <Typography variant="h6" color="secondary" style={{ minWidth: 140, lineHeight: '48px' }} align="center">
                    {tableViewStartDate}
                  </Typography>
                  <IconButton
                    onClick={() => setTableViewPage(tableViewPage + 1)}
                    disabled={tableViewPage >= 13}
                    color="secondary"
                    style={{ height: 24, width: 24 }}
                  >
                    <ForwardIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}

          {!isMobile && (
            <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
              <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
                Minhas Reservas
              </Typography>

              {viewMode === 'table' && (
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => setTableViewPage(tableViewPage - 1)}
                    disabled={tableViewPage <= 0}
                    color="secondary"
                    style={{ height: 24, width: 24 }}
                  >
                    <BackIcon fontSize="small" style={{ marginRight: -7 }} />
                  </IconButton>
                  <Typography variant="h6" color="secondary" style={{ minWidth: 140 }} align="center">
                    {tableViewStartDate} até {tableViewEndDate}
                  </Typography>
                  <IconButton
                    onClick={() => setTableViewPage(tableViewPage + 1)}
                    disabled={tableViewPage >= 13}
                    color="secondary"
                    style={{ height: 24, width: 24 }}
                  >
                    <ForwardIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}

              <Button style={{ minWidth: 150, fontSize: 13 }} variant="contained" onClick={handleChangeViewMode}>
                {viewMode === 'table' ? 'Modo Calendário' : 'Modo Agenda'}
              </Button>
            </Box>
          )}

          {viewMode === 'table' && <TableView tableViewCurrentSlice={tableViewCurrentSlice} />}
          {viewMode === 'list' && <ListView />}
        </Card>
      </Grid>

      <Grid item xs={12} md={4} style={{ padding: 12 }}>
        <Statement />
      </Grid>
    </Grid>
  );
}
