import produce from 'immer';

const INITIAL_STATE = {
  hoursOfDay: [
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
  ],

  loading: false,
  dates: [],
  selectedReservation: null,
  reservationAction: null,
  userUpcomingReservations: [],
  reservationsByMonth: [],
  lockedReservationsMap: {},
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_OUT':
        draft.selectedReservation = null;
        draft.userUpcomingReservations = [];
        draft.reservationsByMonth = [];
        draft.lockedReservationsMap = {};
        break;

      case '@reservation/SET_DATES':
        draft.dates = action.payload.dates;
        break;

      case '@reservation/SET_SELECTED_RESERVATION':
        draft.selectedReservation = action.payload.selectedReservation;
        draft.reservationAction = action.payload.action;
        break;

      case '@reservation/CLEAR_SELECTED_RESERVATION':
        draft.selectedReservation = null;
        draft.reservationAction = null;
        break;

      case '@reservation/UPDATE_SELECTED_RESERVATION_INFO':
        const { field, value } = action.payload;
        draft.selectedReservation[field] = value;
        break;

      case '@reservation/MERGE_RESERVATION_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/MERGE_RESERVATION_SUCCESS': {
        const { mergedReservation } = action.payload;
        draft.userUpcomingReservations = [mergedReservation, ...state.userUpcomingReservations.filter(r => r.id !== mergedReservation.id)];
        draft.loading = false;
        break;
      }

      case '@reservation/MERGE_RESERVATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/DELETE_RESERVATION_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/DELETE_RESERVATION_SUCCESS': {
        const { deletedReservation } = action.payload;
        draft.userUpcomingReservations = [...state.userUpcomingReservations.filter(r => r.id !== deletedReservation.id)];
        draft.loading = false;
        break;
      }

      case '@reservation/DELETE_RESERVATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_UPCOMING_REQUEST': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_UPCOMING_SUCCESS': {
        draft.userUpcomingReservations = action.payload.userUpcomingReservations;
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_UPCOMING_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_LOCKED_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/LIST_LOCKED_SUCCESS': {
        draft.lockedReservationsMap = action.payload.lockedReservationsMap;
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_LOCKED_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_RESERVATION_BY_MONTH_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@reservation/LIST_USER_RESERVATION_BY_MONTH_SUCCESS': {
        draft.reservationsByMonth = action.payload.reservationsByMonth;
        draft.loading = false;
        break;
      }

      case '@reservation/LIST_USER_RESERVATION_BY_MONTH_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@recurrency/MERGE_RECURRENCY_SUCCESS': {
        const { reservations } = action.payload.mergedRecurrency;
        // TODO: check for existing ids
        const reservationIDMap = new Set(reservations.map(r => r.id));
        draft.userUpcomingReservations = [...reservations, ...state.userUpcomingReservations.filter(r => !reservationIDMap.has(r.id))];
        // draft.userUpcomingReservations = [...reservations, ...state.userUpcomingReservations];
        break;
      }

      default:
    }
  });
}
