import React from 'react';
import { useSelector } from 'react-redux';

import { Box, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import UserManagementTab from './UserManagement';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    background: theme.palette.background.paper,
    color: theme.palette.secondary.main,
  },
}));

export default function Overview() {
  const classes = useStyles();

  const { is_admin: isAdmin } = useSelector(state => state.auth.user);

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (_, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box p={1}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    isAdmin && (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
            <Tab label="Gerência de Usuário" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <UserManagementTab />
        </TabPanel>
      </div>
    )
  );
}
