export function clearSelectedRecurrency() {
  return {
    type: '@recurrency/CLEAR_SELECTED_RECURRENCY',
  };
}

export function setSelectedRecurrency(selectedRecurrency, action) {
  return {
    type: '@recurrency/SET_SELECTED_RECURRENCY',
    payload: {
      selectedRecurrency,
      action,
    },
  };
}

export function updateSelectedRecurrencyInfo(field, value) {
  return {
    type: '@recurrency/UPDATE_SELECTED_RECURRENCY_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function mergeRecurrencyRequest(recurrency, reservations, action) {
  return {
    type: '@recurrency/MERGE_RECURRENCY_REQUEST',
    payload: {
      recurrency,
      reservations,
      action,
    },
  };
}

export function mergeRecurrencySuccess(mergedRecurrency) {
  return {
    type: '@recurrency/MERGE_RECURRENCY_SUCCESS',
    payload: {
      mergedRecurrency,
    },
  };
}

export function mergeRecurrencyFailure() {
  return {
    type: '@recurrency/MERGE_RECURRENCY_FAILURE',
  };
}

export function deleteRecurrencyRequest(recurrencyId) {
  return {
    type: '@recurrency/DELETE_RECURRENCY_REQUEST',
    payload: {
      recurrencyId,
    },
  };
}

export function deleteRecurrencySuccess(deletedRecurrency) {
  return {
    type: '@recurrency/DELETE_RECURRENCY_SUCCESS',
    payload: {
      deletedRecurrency,
    },
  };
}

export function deleteRecurrencyFailure() {
  return {
    type: '@recurrency/DELETE_RECURRENCY_FAILURE',
  };
}

export function listUserRecurrenciesRequest() {
  return {
    type: '@recurrency/LIST_USER_RECURRENCIES_REQUEST',
  };
}

export function listUserRecurrenciesSuccess(userRecurrencies) {
  return {
    type: '@recurrency/LIST_USER_RECURRENCIES_SUCCESS',
    payload: {
      userRecurrencies,
    },
  };
}

export function listUserRecurrenciesFailure() {
  return {
    type: '@recurrency/LIST_USER_RECURRENCIES_FAILURE',
  };
}

export function listAllRecurrenciesRequest() {
  return {
    type: '@recurrency/LIST_ALL_RECURRENCIES_REQUEST',
  };
}

export function listAllRecurrenciesSuccess(allRecurrencies) {
  return {
    type: '@recurrency/LIST_ALL_RECURRENCIES_SUCCESS',
    payload: {
      allRecurrencies,
    },
  };
}

export function listAllRecurrenciesFailure() {
  return {
    type: '@recurrency/LIST_ALL_RECURRENCIES_FAILURE',
  };
}
