import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  clearSelectedRecurrency,
  mergeRecurrencyRequest,
  deleteRecurrencyRequest,
  updateSelectedRecurrencyInfo,
} from '~/store/modules/recurrency/actions';

import { listUserUpcomingRequest, listLockedRequest } from '~/store/modules/reservation/actions';
import { getDayLabel, isPast } from '~/services/utils';
import { updateProfileInfo } from '~/store/modules/user/actions';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 24px 24px',
  },

  deleteButton: {
    width: 120,
    background: '#FFF',
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.dark,
    },
  },

  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    border: '1px solid #F9F9F9',
    textAlign: 'center',
  },
}));

const filter = createFilterOptions();

const shiftHours = {
  morning: new Set(['08:00', '09:00', '10:00', '11:00']),
  evening: new Set(['14:00', '15:00', '16:00', '17:00']),
  night: new Set(['18:00', '19:00', '20:00', '21:00']),
};

const daysOptions = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const stringDayToNumber = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { dates, userUpcomingReservations, lockedReservationsMap } = useSelector(state => state.reservation);

  const { selectedRecurrency, recurrencyAction, userRecurrencies, allRecurrencies, hoursOfDay } = useSelector(state => state.recurrency);
  const { pacientList } = useSelector(state => state.user.profile);

  const [lockedRecurrency, setLockedRecurrency] = useState(false);
  const [conflictedReservations, setConflictedReservations] = useState([]);
  const [reservationsToCreate, setReservationsToCreate] = useState([]);
  const [userUpcomingReservationsMap, setUserUpcomingReservationsMap] = useState({});
  const [step, setStep] = useState('config');

  const handleClose = () => {
    setStep('config');
    setConflictedReservations([]);
    setReservationsToCreate([]);
    dispatch(clearSelectedRecurrency());
  };

  const handleCreateRecurrency = () => {
    dispatch(mergeRecurrencyRequest(selectedRecurrency, reservationsToCreate, recurrencyAction));
    handleClose();
  };

  const handleDelete = recurrencyId => {
    dispatch(deleteRecurrencyRequest(recurrencyId));
  };

  const handleChange = (field, value) => {
    if (field === 'type') dispatch(updateSelectedRecurrencyInfo('time', ''));
    dispatch(updateSelectedRecurrencyInfo(field, value));
  };

  const handleChangePacient = pacient => {
    dispatch(updateSelectedRecurrencyInfo('pacient', pacient?.value));

    if (pacient && !pacientList.includes(pacient.value)) {
      dispatch(updateProfileInfo('pacientList', [...pacientList, pacient.value].sort()));
    }
  };

  const isInvalidRecurrency = () => {
    if (!selectedRecurrency?.type) return true;
    if (!selectedRecurrency?.date) return true;
    if (!selectedRecurrency?.time) return true;
    if (!selectedRecurrency?.room) return true;

    return false;
  };

  const handleBack = () => {
    setStep('config');
    setConflictedReservations([]);
    setReservationsToCreate([]);
  };

  const handleNext = () => {
    const { type, date: recurrencyDate, time, room, pacient } = selectedRecurrency;
    const numberDay = stringDayToNumber[recurrencyDate];
    const updatedConflitedReservations = [];
    const updatedReservationsToCreate = [];

    const times = [];

    if (type === 'HOUR') {
      times.push(time);
    } else {
      shiftHours[time].forEach(hour => {
        times.push(hour);
      });
    }

    dates.forEach(([stringDate, prettyDate]) => {
      const date = new Date(stringDate + ' 00:00');

      if (numberDay === date.getDay()) {
        times.forEach(hour => {
          const selfKey = [stringDate, hour].join(' ');
          const otherKey = [stringDate, hour, room].join(' ');

          if (selfKey in userUpcomingReservationsMap) {
            const status =
              userUpcomingReservationsMap[selfKey] === room
                ? 'Reservado por você'
                : 'Você possui uma reserva em outra sala no mesmo horário';

            updatedConflitedReservations.push({
              hour,
              prettyDate: prettyDate.split(', ')[1],
              status: status,
            });
          } else if (otherKey in lockedReservationsMap) {
            updatedConflitedReservations.push({
              hour,
              prettyDate: prettyDate.split(', ')[1],
              status: 'Reservado por outro profissional',
            });
          } else if (!isPast(stringDate, hour)) {
            updatedReservationsToCreate.push({
              date: stringDate,
              time: hour,
              room,
              pacient,
            });
          }
        });
      }
    });

    if (updatedConflitedReservations.length > 0) {
      setReservationsToCreate(updatedReservationsToCreate);
      setConflictedReservations(updatedConflitedReservations);
      setStep('conflict');
    } else {
      dispatch(mergeRecurrencyRequest(selectedRecurrency, updatedReservationsToCreate, recurrencyAction));
      handleClose();
    }
  };

  useEffect(() => {
    let isLocked = false;

    if (!isInvalidRecurrency()) {
      if (selectedRecurrency.type === 'HOUR') {
        const allConflict = allRecurrencies?.some(({ date, room, type, time }) => {
          const sameDate = date === selectedRecurrency.date;
          const sameRoom = room === selectedRecurrency.room;
          const sameTime = type === 'HOUR' ? time === selectedRecurrency.time : shiftHours[time].has(selectedRecurrency.time);
          if (sameDate && sameRoom && sameTime) return true;
          return false;
        });

        const userConflict = userRecurrencies?.some(({ date, type, time }) => {
          const sameDate = date === selectedRecurrency.date;
          const sameTime = type === 'HOUR' ? time === selectedRecurrency.time : shiftHours[time].has(selectedRecurrency.time);
          if (sameDate && sameTime) return true;
          return false;
        });

        isLocked = allConflict || userConflict;
      } else if (selectedRecurrency.type === 'SHIFT') {
        const allConflict = allRecurrencies?.some(({ date, room, type, time }) => {
          const sameDate = date === selectedRecurrency.date;
          const sameRoom = room === selectedRecurrency.room;
          const sameTime = type === 'SHIFT' ? time === selectedRecurrency.time : shiftHours[selectedRecurrency.time].has(time);
          if (sameDate && sameRoom && sameTime) return true;
          return false;
        });

        const userConflict = userRecurrencies?.some(({ date, type, time }) => {
          const sameDate = date === selectedRecurrency.date;
          const sameTime = type === 'SHIFT' ? time === selectedRecurrency.time : shiftHours[selectedRecurrency.time].has(time);
          if (sameDate && sameTime) return true;
          return false;
        });

        isLocked = allConflict || userConflict;
      }
    }

    setLockedRecurrency(isLocked);
  }, [selectedRecurrency]);

  useEffect(() => {
    if (dates.length > 0) {
      dispatch(listLockedRequest());
      if (userUpcomingReservations.length === 0) {
        dispatch(listUserUpcomingRequest());
      }
    }
  }, [dates, userUpcomingReservations.length, dispatch]);

  useEffect(() => {
    const updatedUserUpcomingReservationsMap = {};

    userUpcomingReservations.forEach(({ date, time, room }) => {
      const selfKey = [date, time].join(' ');
      updatedUserUpcomingReservationsMap[selfKey] = room;
    });

    setUserUpcomingReservationsMap(updatedUserUpcomingReservationsMap);
  }, [userUpcomingReservations, userRecurrencies]);

  return (
    <Dialog open={!!selectedRecurrency} onClose={handleClose} maxWidth="xs" fullWidth>
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">{step === 'config' ? <b>Reserva Recorrente</b> : <b>Conflito de Reservas</b>}</Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {step === 'config' && (
        <DialogContent width="100%">
          {selectedRecurrency && (
            <Box display="flex" flexDirection="column" maxWidth={415}>
              <FormControl variant="outlined" fullWidth style={{ marginBottom: 16, maxWidth: 415 }}>
                <InputLabel id="type">Tipo de Recorrência</InputLabel>
                <Select
                  labelId="type"
                  name="type"
                  value={selectedRecurrency.type}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Tipo de Recorrência"
                  disabled={recurrencyAction === 'update'}
                >
                  <MenuItem value="HOUR">Hora</MenuItem>
                  <MenuItem value="SHIFT">Turno</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 16, maxWidth: 415 }}>
                <InputLabel id="date">Selecione o dia da semana</InputLabel>
                <Select
                  labelId="date"
                  name="date"
                  value={selectedRecurrency.date}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Selecione o dia da semana"
                  disabled={recurrencyAction === 'update'}
                >
                  {daysOptions.map(option => (
                    <MenuItem value={option} key={option}>
                      {getDayLabel(option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
                {selectedRecurrency.type === 'HOUR' && (
                  <>
                    <InputLabel id="time">Selecione um horário</InputLabel>
                    <Select
                      labelId="time"
                      name="time"
                      value={selectedRecurrency.time}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      label="Selecione um horário"
                      disabled={recurrencyAction === 'update'}
                    >
                      {hoursOfDay.map(option => (
                        <MenuItem value={option} key={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}

                {selectedRecurrency.type === 'SHIFT' && (
                  <>
                    <InputLabel id="time">Selecione um turno</InputLabel>
                    <Select
                      labelId="time"
                      name="time"
                      value={selectedRecurrency.time}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                      label="Selecione um horário"
                      disabled={recurrencyAction === 'update'}
                    >
                      <MenuItem value="morning">Manhã</MenuItem>
                      <MenuItem value="evening">Tarde</MenuItem>
                      <MenuItem value="night">Noite</MenuItem>
                    </Select>
                  </>
                )}
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 16, maxWidth: 415 }}>
                <InputLabel id="room">Selecione a sala</InputLabel>
                <Select
                  labelId="room"
                  name="room"
                  value={selectedRecurrency.room}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Selecione a sala"
                  disabled={recurrencyAction === 'update'}
                >
                  <MenuItem value="middle_room">Sala Harmonia</MenuItem>
                  <MenuItem value="lower_room">Sala Equilíbrio</MenuItem>
                  <MenuItem value="upper_room">Sala Manifestação</MenuItem>
                  <MenuItem value="lower_salon">Salão Expansão</MenuItem>
                  <MenuItem value="upper_salon">Salão Conexão</MenuItem>
                </Select>
              </FormControl>

              {selectedRecurrency.type === 'HOUR' && (
                <Autocomplete
                  fullWidth
                  value={selectedRecurrency.pacient || { value: '', label: '' }}
                  onChange={(e, newValue) => handleChangePacient(newValue)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        value: params.inputValue,
                        label: `Adicionar "${params.inputValue}"`,
                        inputValue: params.inputValue,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  options={pacientList.map(p => ({ value: p, label: p }))}
                  getOptionLabel={option => {
                    if (typeof option === 'string') return option;
                    if (option.inputValue) return option.inputValue;
                    return option.label;
                  }}
                  renderOption={option => option.label}
                  freeSolo
                  renderInput={params => <TextField {...params} label="Nome do paciente" variant="outlined" />}
                />
              )}
            </Box>
          )}
        </DialogContent>
      )}

      {step === 'conflict' && (
        <DialogContent width="100%">
          {conflictedReservations && (
            <Box display="flex" flexDirection="column" maxWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Data
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                      Hora
                    </TableCell>
                    <TableCell className={classes.basic} style={{ width: '50%', fontWeight: 'bold' }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {conflictedReservations.map((reservation, index) => (
                    <TableRow key={index} hover>
                      <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                        {reservation.prettyDate}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '25%', fontWeight: 'bold' }}>
                        {reservation.hour}
                      </TableCell>
                      <TableCell className={classes.basic} style={{ width: '50%' }}>
                        {reservation.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </DialogContent>
      )}

      {step === 'config' && (
        <DialogActions className={classes.dialogActions}>
          {recurrencyAction === 'update' ? (
            <>
              <Button
                onClick={() => handleDelete(selectedRecurrency.id)}
                className={classes.deleteButton}
                variant="contained"
                startIcon={<DeleteIcon />}
              >
                Cancelar
              </Button>

              <Button
                onClick={handleCreateRecurrency}
                disabled={isInvalidRecurrency() || lockedRecurrency}
                variant="contained"
                color="primary"
                style={{ width: 120 }}
                startIcon={<CheckIcon />}
              >
                Atualizar
              </Button>
            </>
          ) : (
            <Button
              onClick={handleNext}
              disabled={isInvalidRecurrency() || lockedRecurrency}
              variant="contained"
              color="primary"
              style={{ width: 120 }}
              startIcon={<CheckIcon />}
            >
              Criar
            </Button>
          )}
        </DialogActions>
      )}

      {step === 'conflict' && (
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleBack} variant="contained" color="primary" style={{ width: 120 }}>
            Voltar
          </Button>

          <Button
            onClick={handleCreateRecurrency}
            disabled={isInvalidRecurrency() || lockedRecurrency}
            variant="contained"
            color="primary"
            style={{ width: 120 }}
            startIcon={<CheckIcon />}
          >
            Confirmar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
