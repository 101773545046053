import { Box, Button, Hidden, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import FilterIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { isPast } from '~/services/utils';
import { listLockedRequest, listUserUpcomingRequest, setSelectedReservation } from '~/store/modules/reservation/actions';
import { OpenEnabledRoomsDialog } from '~/store/modules/user/actions';








const useStyles = makeStyles(theme => ({
  root: {
    padding: 12,
  },
  basic: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100,
    fontSize: 12,
    borderRadius: 10,
    border: '3px solid #F9F9F9',
    textAlign: 'center',
  },

  available: {
    cursor: 'pointer',
    background: rgba(0, 155, 159, 0.2),
    '&:hover': {
      background: rgba(0, 155, 159, 0.3),
    },
  },

  reserved: {
    cursor: 'pointer',
    background: rgba(10, 35, 130, 0.15),
    '&:hover': {
      background: rgba(10, 35, 130, 0.25),
    },
  },

  locked: {
    background: rgba(0, 0, 0, 0.03),
    color: rgba(0, 0, 0, 0.26),
  },

  filterButton: {
    fontSize: 13,
    width: 150,
  },

  hoveredAvailable: {
    fontWeight: 'bolder',
    // color: theme.palette.secondary.main,
    background: rgba(0, 155, 159, 0.3),
    // background: '#f2f2f2',
  },

  hoveredReserved: {
    fontWeight: 'bolder',
    // color: theme.palette.secondary.main,
    background: rgba(10, 35, 130, 0.25),
    // background: '#f2f2f2',
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { hoursOfDay, dates, userUpcomingReservations, lockedReservationsMap } = useSelector(state => state.reservation);
  const { enabledRooms, defaultRoom } = useSelector(state => state.user.profile);

  const [pageSize] = useState(isMobile ? 1 : 7);
  const [calendarPage, setCalendarPage] = useState(0);
  const [calendarStartDate, setCalendarStateDate] = useState([]);
  const [calendarEndDate, setCalendarEndDate] = useState([]);
  const [calendarCurrentSlice, setCalendarCurrentSlice] = useState([]);

  const [userReservedMap, setUserReservedMap] = useState({});

  const [hoveredTime, setHoveredTime] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);

  const handleSelect = (key, time, rawDate, availableRooms) => {
    if (key in lockedReservationsMap) return;

    if (key in userReservedMap) {
      dispatch(setSelectedReservation(userReservedMap[key], 'update'));
    } else {
      if (availableRooms.length === 0) return;
      const selectedReservation = {
        date: rawDate,
        time,
        room: defaultRoom || availableRooms[0],
        pacient: null,
        availableRooms: availableRooms,
      };

      dispatch(setSelectedReservation(selectedReservation, 'create'));
    }
  };

  const handleMouseEnter = (time, date, status) => {
    setHoveredTime(time);
    setHoveredDate(date);
    setHoveredStatus(status);
  };

  const handleMouseLeave = () => {
    setHoveredTime(null);
    setHoveredDate(null);
    setHoveredStatus(null);
  };

  useEffect(() => {
    if (dates.length > 0) {
      dispatch(listLockedRequest());
      if (userUpcomingReservations.length === 0) {
        dispatch(listUserUpcomingRequest());
      }
    }
  }, [dates, userUpcomingReservations.length, dispatch]);

  useEffect(() => {
    const updatedUserReservedMap = {};
    userUpcomingReservations.forEach(reservation => {
      const key = `${reservation.date} ${reservation.time}`;
      updatedUserReservedMap[key] = reservation;
    });
    setUserReservedMap(updatedUserReservedMap);
  }, [userUpcomingReservations]);

  useEffect(() => {
    if (dates && dates.length > 0) {
      const startDateIndex = calendarPage * pageSize;
      setCalendarCurrentSlice(dates.slice(startDateIndex, startDateIndex + pageSize));

      const [, sDate] = dates[startDateIndex] || '';
      const [, eDate] = dates[startDateIndex + (pageSize - 1)] || '';

      setCalendarStateDate(sDate.split(',')[1]);
      setCalendarEndDate(eDate.split(',')[1]);
    }
  }, [dates, calendarPage, pageSize]);

  return (
    <>
      <Box align="center" className={classes.root}>
        <Hidden smUp>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '48px' }} color="secondary">
              Agendar Reservas
            </Typography>
            <IconButton onClick={() => dispatch(OpenEnabledRoomsDialog())} color="primary">
              <FilterIcon />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={48}>
            <IconButton
              onClick={() => setCalendarPage(calendarPage - 1)}
              disabled={calendarPage <= 0}
              color="secondary"
              style={{ height: 24, width: 24 }}
            >
              <BackIcon fontSize="small" style={{ marginRight: -7 }} />
            </IconButton>

            <Typography
              variant="h6"
              color="secondary"
              style={{ minWidth: 80, lineHeight: '48px', marginLeft: 40, marginRight: 40 }}
              align="center"
            >
              {calendarStartDate}
            </Typography>
            <IconButton
              onClick={() => setCalendarPage(calendarPage + 1)}
              disabled={calendarPage >= 13}
              color="secondary"
              style={{ height: 24, width: 24 }}
            >
              <ForwardIcon fontSize="small" />
            </IconButton>
          </Box>
        </Hidden>

        <Hidden xsDown>
          <Box display="flex" justifyContent="space-between" width="100%" paddingLeft="60px">
            <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
              Agendar Reservas
            </Typography>

            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => setCalendarPage(calendarPage - 1)}
                disabled={calendarPage <= 0}
                color="secondary"
                style={{ height: 24, width: 24 }}
              >
                <BackIcon fontSize="small" style={{ marginRight: -7 }} />
              </IconButton>
              <Typography variant="h6" color="secondary" style={{ minWidth: 140 }} align="center">
                {calendarStartDate} até {calendarEndDate}
              </Typography>
              <IconButton
                onClick={() => setCalendarPage(calendarPage + 1)}
                disabled={calendarPage >= 13}
                color="secondary"
                style={{ height: 24, width: 24 }}
              >
                <ForwardIcon fontSize="small" />
              </IconButton>
            </Box>

            <Button
              onClick={() => dispatch(OpenEnabledRoomsDialog())}
              variant="contained"
              color="primary"
              className={classes.filterButton}
              endIcon={<FilterIcon />}
            >
              Filtrar Salas
            </Button>
          </Box>
        </Hidden>

        <Table size="small" style={{ height: 'calc(100vh - 108px)' }}>
          <Hidden xsDown>
            <TableHead>
              <TableRow>
                <TableCell className={classes.basic} style={{ width: 40, textAlign: 'left' }} />
                {calendarCurrentSlice.map(([rawDate, formattedDate]) => (
                  <TableCell
                    className={clsx(classes.basic, {
                      [classes.hoveredAvailable]: rawDate === hoveredDate && hoveredStatus === 'AVAILABLE',
                      [classes.hoveredReserved]: rawDate === hoveredDate && hoveredStatus === 'RESERVED',
                    })}
                    style={{ fontWeight: 'bold' }}
                    key={rawDate}
                    align="center"
                  >
                    {formattedDate}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Hidden>

          <TableBody onMouseLeave={() => handleMouseLeave()}>
            {hoursOfDay.map(time => (
              <TableRow key={time}>
                <Hidden xsDown>
                  <TableCell
                    className={clsx(classes.basic, {
                      [classes.hoveredAvailable]: time === hoveredTime && hoveredStatus === 'AVAILABLE',
                      [classes.hoveredReserved]: time === hoveredTime && hoveredStatus === 'RESERVED',
                    })}
                    style={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    {time}
                  </TableCell>
                </Hidden>

                {calendarCurrentSlice.map(([rawDate]) => {
                  const key = `${rawDate} ${time}`;

                  let status = 'AVAILABLE';

                  const availableRooms = [];
                  Object.entries(enabledRooms).forEach(([room, enabled]) => {
                    const room_key = `${key} ${room}`;
                    if (!(room_key in lockedReservationsMap) && enabled) availableRooms.push(room);
                  });

                  if (key in userReservedMap) status = 'RESERVED';
                  else if (availableRooms.length === 0 || isPast(rawDate, time)) status = 'LOCKED';

                  return (
                    <TableCell
                      onMouseEnter={() => handleMouseEnter(time, rawDate, status)}
                      className={clsx(classes.basic, {
                        [classes.available]: status === 'AVAILABLE',
                        [classes.reserved]: status === 'RESERVED',
                        [classes.locked]: status === 'LOCKED',
                      })}
                      key={key}
                      onClick={() => {
                        if (status === 'AVAILABLE' || status === 'RESERVED') handleSelect(key, time, rawDate, availableRooms);
                      }}
                    >
                      {isMobile && time}
                      {status === 'AVAILABLE' && !isMobile && 'Disponível'}
                      {status === 'RESERVED' && !isMobile && `${userReservedMap[key].pacient || 'Não Informado'}`}
                      {status === 'LOCKED' && !isMobile && 'Indisponível'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
