import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { cleanReset, userListRequest, userUpdateByAdminRequest } from '~/store/modules/admin/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import MaskedInput from 'react-text-mask';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _ from 'lodash';

import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: 8,
    marginTop: 12,
    width: 150,
  },
  buttonLoading: {
    width: 75,
  },
}));

function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

function CPFMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

export default function UserEditTab() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { users, loading, shouldClean } = useSelector(state => state.admin);

  const [selectedUser, setSelectedUser] = useState(null);
  const [referenceUser, setReferenceUser] = useState(null);
  const [sendEmailOption, setSendEmailOption] = useState(false);

  const handleChange = (name, value) => {
    const newSelectedUser = { ...selectedUser, send_email: 0 };
    newSelectedUser[name] = value;
    setSelectedUser(newSelectedUser);
  };

  const handleSelect = newValue => {
    setSelectedUser(newValue);
    setReferenceUser(_.cloneDeep(newValue));
  };

  useEffect(() => {
    dispatch(userListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!referenceUser?.is_active && selectedUser?.is_active) setSendEmailOption(true);
    else setSendEmailOption(false);
  }, [selectedUser, referenceUser]);

  useEffect(() => {
    if (shouldClean) {
      setSelectedUser(null);
      dispatch(cleanReset());
    }
  }, [shouldClean, dispatch]);

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            value={selectedUser}
            onChange={(e, newValue) => handleSelect(newValue)}
            options={users}
            getOptionSelected={option => option.username === selectedUser.username}
            getOptionLabel={option => option.username}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                name="username"
                style={{ marginBottom: 4 }}
                label="Nome Completo"
                placeholder="Selecione um usuário"
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            )}
            freeSolo
          />
        </Grid>

        {selectedUser && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                autoComplete="off"
                value={selectedUser?.email || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="exemplo@gmail.com"
                label="Email"
                type="email"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="cpf"
                value={selectedUser?.cpf || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="CPF"
                label="CPF"
                fullWidth
                InputProps={{
                  inputComponent: CPFMask,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="rg"
                value={selectedUser?.rg || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="RG"
                label="RG"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="phone_number"
                value={selectedUser?.phone_number || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="Telefone"
                label="Telefone"
                fullWidth
                InputProps={{
                  inputComponent: PhoneMask,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="address"
                value={selectedUser?.address || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="Endereço"
                label="Endereço"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="profession"
                value={selectedUser?.profession || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="Profissão"
                label="Profissão"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="off"
                name="license_number"
                value={selectedUser?.license_number || ''}
                onChange={e => handleChange(e.target.name, e.target.value)}
                variant="outlined"
                placeholder="Registro de Profissão (se aplicável)"
                label="Registro de Profissão"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={sendEmailOption ? 3 : 6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="is_active_label">Usuário ativo?</InputLabel>
                <Select
                  labelId="is_active_label"
                  name="is_active"
                  value={selectedUser?.is_active || 0}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Usuário ativo?"
                >
                  <MenuItem value={0}>Não</MenuItem>
                  <MenuItem value={1}>Sim</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {sendEmailOption && (
              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="send_email_label">Enviar email de ativação?</InputLabel>
                  <Select
                    labelId="send_email_label"
                    name="send_email"
                    value={selectedUser?.send_email || 0}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    label="Enviar email de ativação?"
                  >
                    <MenuItem value={0}>Não</MenuItem>
                    <MenuItem value={1}>Sim</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="is_admin_label">Usuário administrador?</InputLabel>
                <Select
                  labelId="is_admin_label"
                  name="is_admin"
                  value={selectedUser?.is_admin || 0}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  label="Usuário administrador?"
                >
                  <MenuItem value={0}>Não</MenuItem>
                  <MenuItem value={1}>Sim</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Button
              variant="contained"
              onClick={() => dispatch(userUpdateByAdminRequest(selectedUser))}
              disabled={_.isEqual(selectedUser, referenceUser)}
              className={clsx(classes.button, {
                [classes.buttonLoading]: loading,
              })}
            >
              {loading ? <CircularProgress color="inherit" size={30} /> : 'Salvar Alterações'}
            </Button>
          </>
        )}
      </Grid>
    </Box>
  );
}
