const weekdays = {
  0: 'Dom',
  1: 'Seg',
  2: 'Ter',
  3: 'Qua',
  4: 'Qui',
  5: 'Sex',
  6: 'Sáb',
};

const daysLabelMap = {
  sunday: 'Domingo',
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
};

const shortMonths = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Maio',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
};

const longMonths = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

const roomLabelMap = {
  lower_room: 'Sala Equilíbrio',
  middle_room: 'Sala Harmonia',
  upper_room: 'Sala Manifestação',
  lower_salon: 'Salão Expansão',
  upper_salon: 'Salão Conexão',
};

const shiftLabel = {
  morning: 'Manhã',
  evening: 'Tarde',
  night: 'Noite',
};
export const getRoomNameMap = () => roomLabelMap;

export const getRoomName = room => roomLabelMap[room] || 'Sala Indefinida';

export const getDayLabel = day => daysLabelMap[day] || 'Dia Indefinido';

export const getTimeLabel = (time, type) => {
  if (type === 'HOUR') return time;
  return shiftLabel[time];
};

const getMonth = (m, variant = 'short') => {
  if (variant === 'long') return longMonths[m];
  else return shortMonths[m];
};

export const cloneObject = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const formatRawDate = date => {
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  const formattedDay = `${dd > 9 ? '' : '0'}${dd}`;
  const formattedMonth = `${mm > 9 ? '' : '0'}${mm}`;

  return `${yyyy}-${formattedMonth}-${formattedDay}`;
};

export const formatPrettyDate = date => {
  const weekday = date.getDay();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const formattedDay = `${dd > 9 ? '' : '0'}${dd}`;

  return `${weekdays[weekday]}, ${formattedDay} ${getMonth(mm)}`;
};

export const stringToPrettyDate = stringDate => {
  var [year, month, date] = stringDate.split('-');
  month = parseInt(month, 10) - 1;
  return formatPrettyDate(new Date(year, month, date));
};

export const currentMonthAndYear = () => {
  const today = new Date();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();

  return `${getMonth(mm, 'long')}/${yyyy}`;
};

export const getCurrentTime = () => {
  const today = new Date();
  const hh = today.getHours();
  const min = today.getMinutes();
  return `${hh > 9 ? '' : '0'}${hh}:${min > 9 ? '' : '0'}${min}`;
};

export const isPast = (date, time) => {
  const now = new Date();
  const eventDateTime = new Date(`${date} ${time}`);
  return eventDateTime < now;
};

export const isCancelable = (date, time) => {
  const limitDate = new Date();
  const eventDateTime = new Date(`${date} ${time}`);
  limitDate.setDate(limitDate.getDate() + 1);
  return eventDateTime > limitDate;
};

export const getReservationPrice = (room, count) => {
  if (count < 5) return 35;
  else if (count < 20) return 35;
  else return 35;
};
