import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { rgba } from 'polished';
import { setSelectedReservation } from '~/store/modules/reservation/actions';

import { isMobile } from 'react-device-detect';
import { getRoomName } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  basic: {
    fontSize: 12,
    padding: 5,
    height: 35,
    borderRadius: 10,
    border: '1px solid #DDD',
    textAlign: 'center',
    background: '#FFF',

    maxWidth: 100,
  },

  available: {
    background: '#FFF',
  },

  reserved: {
    height: 25,
    borderRadius: 10,
    background: rgba(10, 35, 130, 0.15),
    '-webkit-transition': 'background 0.4s ease-in-out',
    transition: 'background 0.4s ease-in-out',
    '&:hover': {
      background: rgba(10, 35, 130, 0.25),
      cursor: 'pointer',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default function TableView({ tableViewCurrentSlice }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { hoursOfDay, userUpcomingReservations } = useSelector(state => state.reservation);

  const [reservationMap, setReservationMap] = useState({});

  const handleSelect = key => {
    dispatch(setSelectedReservation(reservationMap[key], 'update'));
  };

  useEffect(() => {
    const newReservationMap = {};

    userUpcomingReservations.forEach(reservation => {
      const key = `${reservation.date} ${reservation.time}`;
      newReservationMap[key] = reservation;
    });

    setReservationMap(newReservationMap);
  }, [userUpcomingReservations]);

  return (
    <Table size="small" style={{ height: 'calc(100vh - 150px)' }}>
      {!isMobile && (
        <TableHead>
          <TableRow>
            <TableCell className={classes.basic} style={{ width: '5.5%', textAlign: 'left' }} />
            {tableViewCurrentSlice.map(date => (
              <TableCell className={classes.basic} style={{ width: '13.5%', fontWeight: 'bold' }} key={date[1]}>
                {date[1]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}

      <TableBody>
        {hoursOfDay.map(time => (
          <TableRow key={time}>
            <TableCell className={classes.basic} style={{ fontWeight: 'bold', width: 80 }}>
              {time}
            </TableCell>
            {tableViewCurrentSlice.map(date => {
              const key = date[0] + ' ' + time;
              let status = 'AVAILABLE';
              if (key in reservationMap) status = 'RESERVED';

              return (
                <TableCell className={classes.basic} key={key}>
                  <Tooltip title={getRoomName(reservationMap[key]?.room)}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      className={clsx({
                        [classes.available]: status === 'AVAILABLE',
                        [classes.reserved]: status === 'RESERVED',
                      })}
                      onClick={() => handleSelect(key)}
                    >
                      {status === 'AVAILABLE' && ''}
                      {status === 'RESERVED' && (reservationMap[key].pacient || 'Não Informado')}
                    </Box>
                  </Tooltip>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
