import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import AfterSignUp from '~/pages/AfterSignUp';
import ForgotPassword from '~/pages/ForgotPassword';
import PasswordReset from '~/pages/PasswordReset';

import Overview from '~/pages/Overview';
import Calendar from '~/pages/Calendar';
import Recurrency from '~/pages/Recurrency';
import Profile from '~/pages/Profile';
import Admin from '~/pages/Admin';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/register_success" component={AfterSignUp} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/password_reset/:token" component={PasswordReset} />

      <Route path="/overview" component={Overview} isPrivate />
      <Route path="/calendar" component={Calendar} isPrivate />
      <Route path="/recurrency" component={Recurrency} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/admin" component={Admin} isPrivate isAdmin />
    </Switch>
  );
}
