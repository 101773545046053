import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Grid, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import { isMobile } from 'react-device-detect';

import {
  clearSelectedReservation,
  mergeReservationRequest,
  deleteReservationRequest,
  updateSelectedReservationInfo,
} from '~/store/modules/reservation/actions';
import { stringToPrettyDate, isCancelable, isPast, getRoomName } from '~/services/utils';
import { updateProfileInfo } from '~/store/modules/user/actions';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '24px 24px 16px 24px',
  },

  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 24px 24px',
  },

  deleteButton: {
    width: 120,
    background: '#FFF',
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.error.contrastText,
      background: theme.palette.error.dark,
    },
  },
}));

const filter = createFilterOptions();

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedReservation, reservationAction, lockedReservationsMap } = useSelector(state => state.reservation);
  const { pacientList, enabledRooms } = useSelector(state => state.user.profile);

  const [isPastEvent, setIsPastEvent] = useState();
  const [availableRooms, setAvailableRooms] = useState([]);

  const handleClose = () => {
    dispatch(clearSelectedReservation());
  };

  const handleReserve = () => {
    dispatch(mergeReservationRequest(selectedReservation, reservationAction));
  };

  const handleDelete = reservationId => {
    dispatch(deleteReservationRequest(reservationId));
  };

  const handleChangeRoom = room => {
    dispatch(updateSelectedReservationInfo('room', room.value));
  };

  const handleChangePacient = pacient => {
    dispatch(updateSelectedReservationInfo('pacient', pacient?.value));

    if (pacient && !pacientList.includes(pacient.value)) {
      dispatch(updateProfileInfo('pacientList', [...pacientList, pacient.value].sort()));
    }
  };

  useEffect(() => {
    if (selectedReservation) {
      const isPE = isPast(selectedReservation.date, selectedReservation.time);
      setIsPastEvent(isPE);
      if (!isPE) {
        if (selectedReservation.availableRooms) setAvailableRooms(selectedReservation.availableRooms);
        else {
          const rooms = [];
          Object.entries(enabledRooms).forEach(([room, enabled]) => {
            if (enabled) {
              const key = `${selectedReservation.date} ${selectedReservation.time} ${room}`;
              if (!(key in lockedReservationsMap)) rooms.push(room);
            }
          });
          setAvailableRooms(rooms);
        }
      }
    }
  }, [selectedReservation, enabledRooms, lockedReservationsMap]);

  return (
    <Dialog open={!!selectedReservation} onClose={handleClose} maxWidth="md">
      <Box className={classes.dialogTitle} display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h4">
          <b>Confirme {isMobile ? '' : 'os dados de'} sua reserva</b>
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        {selectedReservation && (
          <Grid container spacing={1}>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5" gutterBottom color="secondary">
                <b>{stringToPrettyDate(selectedReservation.date)}</b> às <b>{selectedReservation.time}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                disabled={isPastEvent}
                disableClearable
                value={{ value: selectedReservation.room, label: getRoomName(selectedReservation.room) }}
                onChange={(e, newValue) => handleChangeRoom(newValue)}
                options={availableRooms.map(r => ({ value: r, label: getRoomName(r) }))}
                getOptionSelected={option => option.value === selectedReservation.room}
                getOptionLabel={option => option.label}
                renderInput={params => <TextField {...params} variant="outlined" style={{ marginBottom: 16 }} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                disabled={isPastEvent}
                value={selectedReservation.pacient || { value: '', label: '' }}
                onChange={(e, newValue) => handleChangePacient(newValue)}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    filtered.push({
                      value: params.inputValue,
                      label: `Adicionar "${params.inputValue}"`,
                      inputValue: params.inputValue,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                options={pacientList.map(p => ({ value: p, label: p }))}
                getOptionLabel={option => {
                  if (typeof option === 'string') return option;
                  if (option.inputValue) return option.inputValue;
                  return option.label;
                }}
                renderOption={option => option.label}
                freeSolo
                renderInput={params => <TextField {...params} label="Nome do paciente" variant="outlined" />}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {reservationAction === 'update' ? (
          <Button
            disabled={!isCancelable(selectedReservation.date, selectedReservation.time)}
            onClick={() => handleDelete(selectedReservation.id)}
            className={classes.deleteButton}
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Cancelar
          </Button>
        ) : (
          <div />
        )}

        <Button
          disabled={isPastEvent}
          onClick={handleReserve}
          variant="contained"
          color="primary"
          style={{ width: 120 }}
          startIcon={<CheckIcon />}
        >
          {reservationAction === 'create' ? 'Reservar' : 'Atualizar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
