import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { formatRawDate } from '~/services/utils';

import {
  clearSelectedReservation,
  listUserUpcomingSuccess,
  listUserUpcomingFailure,
  listLockedSuccess,
  listLockedFailure,
  listUserReservationsByMonthSuccess,
  listUserReservationsByMonthFailure,
  mergeReservationSuccess,
  mergeReservationFailure,
  deleteReservationSuccess,
  deleteReservationFailure,
} from './actions';

export function* mergeReservation({ payload }) {
  const { reservation, action } = payload;
  try {
    const method = action === 'create' ? api.post : api.put;
    const route = action === 'create' ? 'reservations' : `reservations/${reservation.id}`;

    const response = yield call(method, route, reservation);

    const mergedReservation = response.data;

    yield put(mergeReservationSuccess(mergedReservation));
    yield put(clearSelectedReservation());
  } catch (err) {
    yield put(mergeReservationFailure());
    toast.error(`Falha ao ${action === 'create' ? 'criar' : 'atualizar'} a reserva`);
  }
}

export function* deleteReservation({ payload }) {
  const { reservationId } = payload;
  try {
    const response = yield call(api.delete, `reservations/${reservationId}`);

    const deletedReservation = response.data;

    yield put(deleteReservationSuccess(deletedReservation));
    yield put(clearSelectedReservation());
  } catch (err) {
    yield put(deleteReservationFailure());
    toast.error(`Falha ao deletar a reserva`);
  }
}

export function* listUserUpcomingReservations() {
  try {
    const response = yield call(api.get, 'reservations');

    const userUpcomingReservations = response.data;

    yield put(listUserUpcomingSuccess(userUpcomingReservations));
  } catch (err) {
    yield put(listUserUpcomingFailure());
    toast.error('Falha ao buscar as reservas');
  }
}

export function* listLockedReservations() {
  try {
    const response = yield call(api.get, 'reservations', { params: { locked: true } });

    const lockedReservations = response.data;

    const lockedReservationsMap = {};

    lockedReservations.forEach(({ date, time, room }) => {
      const key = `${date} ${time} ${room}`;
      lockedReservationsMap[key] = null;
    });

    yield put(listLockedSuccess(lockedReservationsMap));
  } catch (err) {
    yield put(listLockedFailure());
    toast.error('Falha ao buscar as reservas');
  }
}

export function* listUserReservationsByMonth() {
  try {
    const today = formatRawDate(new Date());
    const startDate = `${today.slice(0, -2)}00`;
    const endDate = `${today.slice(0, -2)}99`;

    const response = yield call(api.get, 'reservations', { params: { start_date: startDate, end_date: endDate } });

    const reservationsByMonth = response.data;

    yield put(listUserReservationsByMonthSuccess(reservationsByMonth));
  } catch (err) {
    yield put(listUserReservationsByMonthFailure());
    toast.error('Falha ao buscar as reservas');
  }
}

export default all([
  takeLatest('@reservation/MERGE_RESERVATION_REQUEST', mergeReservation),
  takeLatest('@reservation/DELETE_RESERVATION_REQUEST', deleteReservation),
  takeLatest('@reservation/LIST_USER_UPCOMING_REQUEST', listUserUpcomingReservations),
  takeLatest('@reservation/LIST_USER_RESERVATION_BY_MONTH_REQUEST', listUserReservationsByMonth),
  takeLatest('@reservation/LIST_LOCKED_REQUEST', listLockedReservations),
]);
