import { cloneObject } from '~/services/utils';

export function setDates(dates) {
  return {
    type: '@reservation/SET_DATES',
    payload: {
      dates,
    },
  };
}

export function openReservationDialog() {
  return {
    type: '@reservation/OPEN_RESERVATION_DIALOG',
  };
}

export function closeReservationDialog() {
  return {
    type: '@reservation/CLOSE_RESERVATION_DIALOG',
  };
}

export function clearSelectedReservation() {
  return {
    type: '@reservation/CLEAR_SELECTED_RESERVATION',
  };
}

export function setSelectedReservation(selectedReservation, action) {
  return {
    type: '@reservation/SET_SELECTED_RESERVATION',
    payload: {
      selectedReservation,
      action,
    },
  };
}

export function updateSelectedReservationInfo(field, value) {
  return {
    type: '@reservation/UPDATE_SELECTED_RESERVATION_INFO',
    payload: {
      field,
      value,
    },
  };
}

export function setSelectedReservations(selectedReservations) {
  return {
    type: '@reservation/SET_SELECTED_RESERVATIONS',
    payload: {
      selectedReservations,
    },
  };
}

export function setSelectedRooms(selectedRooms) {
  return {
    type: '@reservation/SET_SELECTED_ROOMS',
    payload: {
      selectedRooms,
    },
  };
}

export function mergeReservationRequest(reservation, action) {
  return {
    type: '@reservation/MERGE_RESERVATION_REQUEST',
    payload: {
      reservation: cloneObject(reservation),
      action,
    },
  };
}

export function mergeReservationSuccess(mergedReservation) {
  return {
    type: '@reservation/MERGE_RESERVATION_SUCCESS',
    payload: {
      mergedReservation,
    },
  };
}

export function mergeReservationFailure() {
  return {
    type: '@reservation/MERGE_RESERVATION_FAILURE',
  };
}

export function deleteReservationRequest(reservationId) {
  return {
    type: '@reservation/DELETE_RESERVATION_REQUEST',
    payload: {
      reservationId,
    },
  };
}

export function deleteReservationSuccess(deletedReservation) {
  return {
    type: '@reservation/DELETE_RESERVATION_SUCCESS',
    payload: {
      deletedReservation,
    },
  };
}

export function deleteReservationFailure() {
  return {
    type: '@reservation/DELETE_RESERVATION_FAILURE',
  };
}

export function listUserUpcomingRequest() {
  return {
    type: '@reservation/LIST_USER_UPCOMING_REQUEST',
  };
}

export function listUserUpcomingSuccess(userUpcomingReservations) {
  return {
    type: '@reservation/LIST_USER_UPCOMING_SUCCESS',
    payload: {
      userUpcomingReservations,
    },
  };
}

export function listUserUpcomingFailure() {
  return {
    type: '@reservation/LIST_USER_UPCOMING_FAILURE',
  };
}

export function listLockedRequest() {
  return {
    type: '@reservation/LIST_LOCKED_REQUEST',
  };
}

export function listLockedSuccess(lockedReservationsMap) {
  return {
    type: '@reservation/LIST_LOCKED_SUCCESS',
    payload: {
      lockedReservationsMap,
    },
  };
}

export function listLockedFailure() {
  return {
    type: '@reservation/LIST_LOCKED_FAILURE',
  };
}

export function listUserReservationsByMonthRequest() {
  return {
    type: '@reservation/LIST_USER_RESERVATION_BY_MONTH_REQUEST',
  };
}

export function listUserReservationsByMonthSuccess(reservationsByMonth) {
  return {
    type: '@reservation/LIST_USER_RESERVATION_BY_MONTH_SUCCESS',
    payload: {
      reservationsByMonth,
    },
  };
}

export function listUserReservationsByMonthFailure() {
  return {
    type: '@reservation/LIST_USER_RESERVATION_BY_MONTH_FAILURE',
  };
}
