import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import _ from 'lodash';

import {
  clearSelectedRecurrency,
  listUserRecurrenciesSuccess,
  listUserRecurrenciesFailure,
  listAllRecurrenciesSuccess,
  listAllRecurrenciesFailure,
  mergeRecurrencySuccess,
  mergeRecurrencyFailure,
  deleteRecurrencySuccess,
  deleteRecurrencyFailure,
} from './actions';

export function* mergeRecurrency({ payload }) {
  const { recurrency, reservations, action } = payload;
  try {
    const method = action === 'create' ? api.post : api.put;
    const route = action === 'create' ? 'recurrencies' : `recurrencies/${recurrency.id}`;

    const params = _.cloneDeep(recurrency);
    params.reservations = reservations;
    const response = yield call(method, route, params);

    const mergedRecurrency = response.data.recurrency;
    yield put(mergeRecurrencySuccess(mergedRecurrency));
    yield put(clearSelectedRecurrency());
  } catch (err) {
    yield put(mergeRecurrencyFailure());

    const message = err?.response?.data?.message;
    toast.error(message || `Falha ao ${action === 'create' ? 'criar' : 'atualizar'} a reserva recorrente`);
  }
}

export function* deleteRecurrency({ payload }) {
  const { recurrencyId } = payload;
  try {
    const response = yield call(api.delete, `recurrencies/${recurrencyId}`);

    const deletedRecurrency = response.data;

    yield put(deleteRecurrencySuccess(deletedRecurrency));
    yield put(clearSelectedRecurrency());
  } catch (err) {
    yield put(deleteRecurrencyFailure());
    toast.error(`Falha ao deletar a reserva recorrente`);
  }
}

export function* listUserRecurrencies() {
  try {
    const response = yield call(api.get, 'recurrencies');

    const userRecurrencies = response.data;

    yield put(listUserRecurrenciesSuccess(userRecurrencies));
  } catch (err) {
    yield put(listUserRecurrenciesFailure());
    toast.error('Falha ao buscar as reservas recorrentes');
  }
}

export function* listAllRecurrencies() {
  try {
    const response = yield call(api.get, 'recurrencies', { params: { all: true } });

    const lockedRecurrencies = response.data;

    yield put(listAllRecurrenciesSuccess(lockedRecurrencies));
  } catch (err) {
    yield put(listAllRecurrenciesFailure());
    toast.error('Falha ao buscar as reservas recorrentes');
  }
}

export default all([
  takeLatest('@recurrency/MERGE_RECURRENCY_REQUEST', mergeRecurrency),
  takeLatest('@recurrency/DELETE_RECURRENCY_REQUEST', deleteRecurrency),
  takeLatest('@recurrency/LIST_USER_RECURRENCIES_REQUEST', listUserRecurrencies),
  takeLatest('@recurrency/LIST_ALL_RECURRENCIES_REQUEST', listAllRecurrencies),
]);
