import { toast } from 'react-toastify';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';

import { userListSuccess, userListFailure, userUpdateByAdminSuccess, userUpdateByAdminFailure } from './actions';

const sanitizeNumberMask = number => {
  return number.replace(/[^0-9]/g, '');
};

export function* userList() {
  try {
    const response = yield call(api.get, 'users');

    const { users } = response.data;

    yield put(userListSuccess(users));
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(userListFailure(message || 'Falha ao listar usuários'));
  }
}

export function* updateUser({ payload }) {
  try {
    const { user } = payload;

    user.cpf = sanitizeNumberMask(user.cpf);
    user.phone_number = sanitizeNumberMask(user.phone_number);

    const response = yield call(api.put, `users/${user.id}/admin`, user);

    const { user: updatedUser } = response.data;

    yield put(userUpdateByAdminSuccess(updatedUser));
    toast.success('Usuário atualizado com sucesso');
  } catch (err) {
    const message = err?.response?.data?.message;

    yield put(userUpdateByAdminFailure(message || 'Falha ao atualizar o usuário'));
  }
}

export default all([takeLatest('@admin/USER_LIST_REQUEST', userList), takeLatest('@admin/USER_UPDATE_BY_ADMIN_REQUEST', updateUser)]);
