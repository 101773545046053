import produce from 'immer';

const INITIAL_STATE = {
  hoursOfDay: [
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
  ],

  loading: false,
  selectedRecurrency: null,
  recurrencyAction: null,
  userRecurrencies: [],
  allRecurrencies: [],
  // lockedShiftDays: {},
  // lockedHourDays: {},
  // lockedShifts: {},
  // lockedHours: {},
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_OUT':
        draft.selectedRecurrency = null;
        draft.userRecurrencies = [];
        draft.allRecurrencies = {};
        break;

      case '@recurrency/SET_DATES':
        draft.dates = action.payload.dates;
        break;

      case '@recurrency/SET_SELECTED_RECURRENCY':
        draft.selectedRecurrency = action.payload.selectedRecurrency;
        draft.recurrencyAction = action.payload.action;
        break;

      case '@recurrency/CLEAR_SELECTED_RECURRENCY':
        draft.selectedRecurrency = null;
        draft.recurrencyAction = null;
        break;

      case '@recurrency/UPDATE_SELECTED_RECURRENCY_INFO':
        const { field, value } = action.payload;
        draft.selectedRecurrency[field] = value;
        break;

      case '@recurrency/MERGE_RECURRENCY_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@recurrency/MERGE_RECURRENCY_SUCCESS': {
        const { mergedRecurrency } = action.payload;
        draft.userRecurrencies = [mergedRecurrency, ...state.userRecurrencies.filter(r => r.id !== mergedRecurrency.id)];
        draft.loading = false;
        break;
      }

      case '@recurrency/MERGE_RECURRENCY_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@recurrency/DELETE_RECURRENCY_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@recurrency/DELETE_RECURRENCY_SUCCESS': {
        const { deletedRecurrency } = action.payload;
        draft.userRecurrencies = [...state.userRecurrencies.filter(r => r.id !== deletedRecurrency.id)];
        draft.loading = false;
        break;
      }

      case '@recurrency/DELETE_RECURRENCY_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@recurrency/LIST_USER_RECURRENCIES_REQUEST': {
        draft.loading = false;
        break;
      }

      case '@recurrency/LIST_USER_RECURRENCIES_SUCCESS': {
        draft.userRecurrencies = action.payload.userRecurrencies;
        draft.loading = false;
        break;
      }

      case '@recurrency/LIST_USER_RECURRENCIES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@recurrency/LIST_ALL_RECURRENCIES_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@recurrency/LIST_ALL_RECURRENCIES_SUCCESS': {
        draft.allRecurrencies = action.payload.allRecurrencies;
        draft.loading = false;
        break;
      }

      case '@recurrency/LIST_ALL_RECURRENCIES_FAILURE': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
