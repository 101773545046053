import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Wrapper } from './styles';
import Sidebar from './Sidebar';
import Header from './Header';
import ReservationDialog from './ReservationDialog';
import EnabledRoomsDialog from './EnabledRoomsDialog';

import { setDates } from '~/store/modules/reservation/actions';
import { formatRawDate, formatPrettyDate } from '~/services/utils';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 48,
    height: '100%',
    display: 'flex',
  },
  content: {
    height: '100%',
    width: '100%',
    background: theme.palette.background.default,
    overflowY: 'auto',
  },
}));

export default function DashboardLayout({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const numberOfDays = 14 * 7;
    const startDate = new Date();

    dispatch(
      setDates(
        [...Array(numberOfDays).keys()].map(shift => {
          const date = new Date(startDate);
          date.setDate(date.getDate() + shift);

          return [formatRawDate(date), formatPrettyDate(date)];
        })
      )
    );
  }, [dispatch]);

  return (
    <Wrapper className={classes.root}>
      <Header />
      <ReservationDialog />
      <EnabledRoomsDialog />
      <Sidebar />

      <main className={classes.content}>{children}</main>
    </Wrapper>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
