export function userListRequest() {
  return {
    type: '@admin/USER_LIST_REQUEST',
    payload: {},
  };
}

export function userListSuccess(users) {
  return {
    type: '@admin/USER_LIST_SUCCESS',
    payload: {
      users,
    },
  };
}

export function userListFailure(userListError) {
  return {
    type: '@admin/USER_LIST_FAILURE',
    payload: {
      userListError,
    },
  };
}

export function userUpdateByAdminRequest(user) {
  return {
    type: '@admin/USER_UPDATE_BY_ADMIN_REQUEST',
    payload: {
      user,
    },
  };
}

export function userUpdateByAdminSuccess(user) {
  return {
    type: '@admin/USER_UPDATE_BY_ADMIN_SUCCESS',
    payload: {
      user,
    },
  };
}

export function userUpdateByAdminFailure() {
  return {
    type: '@admin/USER_UPDATE_BY_ADMIN_FAILURE',
  };
}

export function cleanReset() {
  return {
    type: '@admin/CLEAN_RESET',
  };
}
