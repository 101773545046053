import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  users: [],
  userListError: null,
  shouldClean: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@admin/USER_LIST_REQUEST': {
        draft.loading = true;
        draft.userListError = null;
        break;
      }

      case '@admin/USER_LIST_SUCCESS': {
        draft.users = action.payload.users;
        draft.loading = false;
        draft.userListError = null;
        break;
      }

      case '@admin/USER_LIST_FAILURE': {
        draft.loading = false;
        draft.userListError = action.payload.userListError;
        break;
      }

      case '@admin/USER_UPDATE_BY_ADMIN_REQUEST': {
        draft.loading = true;
        // draft.userListError = null;
        break;
      }

      case '@admin/USER_UPDATE_BY_ADMIN_SUCCESS': {
        const updatedUser = action.payload.user;
        const updatedUsers = state.users.filter(u => u.id !== updatedUser.id);
        updatedUsers.push(updatedUser);
        draft.shouldClean = true;
        draft.users = updatedUsers;
        draft.loading = false;
        // draft.userListError = null;
        break;
      }

      case '@admin/USER_UPDATE_BY_ADMIN_FAILURE': {
        draft.loading = false;
        // draft.userListError = action.payload.userListError;
        break;
      }

      case '@admin/CLEAN_RESET': {
        draft.shouldClean = false;
        break;
      }

      default:
    }
  });
}
