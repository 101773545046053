import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { signInRequest, clearError } from '~/store/modules/auth/actions';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import logo from '~/assets/logo.svg';
import { Box, Card, TextField, Typography, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 32,
    borderRadius: 12,
    '& .MuiTextField-root': {
      marginBottom: 16,
    },
    width: '50%',
    maxWidth: 500,
    minWidth: 315,
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  linkText: {
    marginLeft: 4,
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
  },
  buttonLoading: {
    width: '30%',
    minWidth: 60,
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, loginError } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(clearError());
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = e => {
    e.preventDefault();

    dispatch(signInRequest(email, password));
  };

  return (
    <Card className={classes.card} elevation={5}>
      <img src={logo} alt="Viver Integral Coworking" />
      <form onSubmit={handleSignIn} noValidate>
        <TextField
          name="email"
          autoComplete="off"
          value={email}
          onChange={e => setEmail(e.target.value)}
          variant="outlined"
          placeholder="exemplo@gmail.com"
          label="Email"
          type="email"
        />
        <TextField
          name="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          variant="outlined"
          placeholder="Senha"
          label="Senha"
          type="password"
          style={{ marginBottom: 0 }}
        />

        <Box height={16} mb={2}>
          {loginError && (
            <Typography variant="subtitle2" color="error">
              {loginError}
            </Typography>
          )}
        </Box>

        <Box display="flex" justifyContent="center" width="100%">
          <Button
            type="submit"
            className={clsx(classes.button, {
              [classes.buttonLoading]: loading,
            })}
          >
            {loading ? <CircularProgress color="inherit" size={30} /> : 'Entrar'}
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" mt={2} className={classes.textBox}>
          <Typography variant="h5">Ainda não possui um usuário?</Typography>

          <Link to="/register">
            <Typography variant="h5" color="secondary" className={classes.linkText}>
              Registre-se aqui!
            </Typography>
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" mt={1} className={classes.textBox}>
          <Typography variant="h5">Esqueceu sua senha?</Typography>

          <Link to="/forgot_password">
            <Typography variant="h5" color="secondary" className={classes.linkText}>
              Clique aqui.
            </Typography>
          </Link>
        </Box>
      </form>
    </Card>
  );
}
