import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { listUserRecurrenciesRequest, listAllRecurrenciesRequest, setSelectedRecurrency } from '~/store/modules/recurrency/actions';

import { getDayLabel, getRoomName, getTimeLabel } from '~/services/utils';
import RecurrencyDialog from './RecurrencyDialog';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 12,
  },

  addButton: {
    fontSize: 13,
    width: 150,
  },

  basic: {
    fontSize: 12,
    padding: 5,
    height: 35,
    borderRadius: 10,
    border: '1px solid #DDD',
    textAlign: 'center',
  },
}));

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userRecurrencies } = useSelector(state => state.recurrency);
  const { defaultRoom } = useSelector(state => state.user.profile);

  const typeMap = {
    HOUR: 'Hora',
    SHIFT: 'Turno',
  };

  const handleAdd = () => {
    dispatch(
      setSelectedRecurrency(
        {
          type: 'HOUR',
          date: '',
          time: '',
          room: defaultRoom,
          pacient: '',
          reservations: [],
        },
        'create'
      )
    );
  };

  const handleEdit = recurrency => {
    dispatch(setSelectedRecurrency(recurrency, 'update'));
  };

  const defaultPacient = type => {
    if (type === 'HOUR') return 'Não Informado';
    return 'Turno';
  };

  useEffect(() => {
    dispatch(listUserRecurrenciesRequest());
    dispatch(listAllRecurrenciesRequest());
  }, []);

  return (
    <>
      <RecurrencyDialog />

      <Box align="center" className={classes.root}>
        <Box display="flex" justifyContent="space-between" width="100%" mb="12px">
          <Typography variant="h5" style={{ fontWeight: 'bold', lineHeight: '36px' }} color="secondary">
            Reservas Recorrentes
          </Typography>

          <Button onClick={() => handleAdd()} variant="contained" color="primary" className={classes.addButton} startIcon={<AddIcon />}>
            Recorrencia
          </Button>
        </Box>

        <Table size="small" style={{ maxHeight: 'calc(100vh - 108px)' }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.basic} style={{ width: '17%', fontWeight: 'bold' }}>
                Tipo de recorrencia
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '17%', fontWeight: 'bold' }}>
                Dia da semana
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '17%', fontWeight: 'bold' }} align="center">
                Hora/Turno
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '17%', fontWeight: 'bold' }}>
                Sala
              </TableCell>
              <TableCell className={classes.basic} style={{ width: '17%', fontWeight: 'bold' }}>
                Paciente
              </TableCell>
              {/* <TableCell className={classes.basic} style={{ width: '15%', fontWeight: 'bold' }} align="center">
                Status
              </TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {userRecurrencies.map(recurrency => (
              <TableRow key={recurrency.id} hover onClick={() => handleEdit(recurrency)} style={{ cursor: 'pointer' }}>
                <TableCell className={classes.basic} style={{ width: '17%' }}>
                  {typeMap[recurrency.type]}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '17%' }}>
                  {getDayLabel(recurrency.date)}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '17%' }} align="center">
                  {getTimeLabel(recurrency.time, recurrency.type)}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '17%' }}>
                  {getRoomName(recurrency.room)}
                </TableCell>
                <TableCell className={classes.basic} style={{ width: '17%' }}>
                  {recurrency.pacient || defaultPacient(recurrency.type)}
                </TableCell>
                {/* <TableCell className={classes.basic} style={{ width: '15%' }} align="center">
                  {recurrency.is_active ? 'Ativa' : 'Invativa'}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
